import { RouteProp } from "@react-navigation/core"
import {
  Body2,
  Button,
  H6,
  openUrl,
  PresentationListView,
  useTheme,
  VectorIconProps,
  VideoPlayer,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useReducer, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, StyleSheet } from "react-native"
import FastImage from "react-native-fast-image"
import { VideoRef } from "react-native-video"

import { ICON_SIZE, ICON_SIZE_MEDIUM } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { faqContext, FaqNor } from "../../features/Providers/FaqProvider"
import { defaultValues, videoStateReducer } from "../ExerciseScreen/Constants"
import { IFaqParamList } from "../MainScreen/MainTabs"

type FaqArticleRouteProp = RouteProp<IFaqParamList, "Faq_Article">
interface IProps {
  route: FaqArticleRouteProp
}

const FaqArticleScreen: FC<IProps> = ({ route }) => {
  const { t } = useTranslation()
  const { faqs, loading, error } = useContext(faqContext)
  const [videoState, dispatch] = useReducer(videoStateReducer, defaultValues)
  const videoRef = useRef<VideoRef>(null)
  const articleData = useMemo<FaqNor | undefined>(
    () => _.find(faqs, f => f.id === route?.params?.id),
    [faqs, route?.params?.id],
  )

  const {
    colors: {
      surface: { background: surface },
      white: { highEmphasis: white },
      primary,
      black: { highEmphasis: black },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      title: {
        color: black,
        marginHorizontal: spacing,
        marginTop: spacing,
        marginBottom: spacing / 2,
      },
      description: {
        color: black,
        marginHorizontal: spacing,
      },
      container: {
        backgroundColor: surface,
      },
      label: { color: primary },
    }),
    [black, spacing, surface, primary],
  )

  const indicator = useMemo(
    () => ({
      color: primary,
      size: ICON_SIZE,
    }),
    [primary],
  )
  const [iconName, setIconName] = useState("play-circle-fill")

  const onEnd = useCallback(() => {
    dispatch({ type: "restart" })
    setIconName("replay-circle-filled")
    setTimeout(() => videoRef?.current?.seek(0), 0)
  }, [])

  const onVideoPress = useCallback(() => {
    setIconName("play-circle-fill")
    dispatch({ type: "play" })
  }, [])

  const icon = useMemo<VectorIconProps>(
    () => ({
      name: iconName,
      category: "MaterialIcons",
      size: ICON_SIZE_MEDIUM,
      color: white,
    }),
    [iconName, white],
  )
  const urlTitle = itemField(articleData, "urlTitle", t)
  const onPress = useCallback(url => () => openUrl(url), [])

  return (
    <PresentationListView isEmpty={_.isEmpty(articleData)} {...{ error, loading, indicator }}>
      <ScrollView style={s.container}>
        {articleData?.video ? (
          <VideoPlayer
            isControls={true}
            hasExtraHeader={false}
            uri={articleData?.video}
            showProgress={false}
            forwardRef={videoRef}
            startPosition={1.5}
            controlledPause={true}
            control={{
              onEnd,
              paused: videoState.paused,
            }}
            {...{ onVideoPress, icon }}
          />
        ) : articleData?.image ? (
          <FastImage style={styles.image} source={{ uri: articleData?.image }} />
        ) : null}
        <H6 style={s.title}>{articleData?.title}</H6>
        <Body2 style={s.description}>{articleData?.description}</Body2>
        <Body2>
          {urlTitle ? (
            <Button mode="text" labelStyle={s.label} onPress={onPress(articleData?.url)}>
              {urlTitle}
            </Button>
          ) : null}
        </Body2>
      </ScrollView>
    </PresentationListView>
  )
}
const styles = StyleSheet.create({
  image: {
    height: 230,
  },
})

export default FaqArticleScreen
