import { Subtitle1, useTheme, useUser, VectorIcon } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import SettingsList from "../../components/SettingsList"
import {
  CHEMO_FORM,
  HORMONE_FORM,
  ICON_SIZE,
  IMMUNO_FORM,
  RADIO_FORM,
} from "../../features/config/Constants"
import { PatientsListNS, SettingsNS } from "../../features/i18n/constants"

const TherapiesScreen = () => {
  const {
    colors: { black },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const { userData } = useUser()
  const s = useMemo(
    () => ({
      rightChildText: {
        marginRight: spacing / 2,
        color: black.mediumEmphasis,
      },
    }),
    [black.mediumEmphasis, spacing],
  )
  const therapiesData = useCallback(
    (data, dataGroupName) => {
      const hasTherapy = data[`has_${dataGroupName}_th`]
      switch (hasTherapy) {
        case "no":
        case "unknown":
          return t(`${PatientsListNS}:no_onco_data`)
        case "to_come":
        case "in_progress":
          const startDate = data[`${dataGroupName}_startdate`]
          return startDate
            ? dayjs(startDate.toDate()).format("DD/MM/YYYY")
            : t(`${PatientsListNS}:date_unknown`)
        case "ended":
          const endDate = data[`${dataGroupName}_enddate`]
          return endDate
            ? dayjs(endDate.toDate()).format("DD/MM/YYYY")
            : t(`${PatientsListNS}:date_unknown`)
        default:
          return null
      }
    },
    [t],
  )

  const renderRightChild = useCallback(
    text => (
      <View style={styles.rightChildContainer}>
        <Subtitle1 style={s.rightChildText}>{text}</Subtitle1>
        <VectorIcon
          name="chevron-right"
          category="MaterialIcons"
          color={black.mediumEmphasis}
          size={ICON_SIZE}
        />
      </View>
    ),
    [s.rightChildText, black.mediumEmphasis],
  )

  const oncoItems = useMemo(
    () =>
      _.filter([
        {
          name: t(`${SettingsNS}:radiotherapy`),
          form: RADIO_FORM,
          rightChild: renderRightChild(
            t("therapy_data", {
              ns: PatientsListNS,
              therapy_data: therapiesData(userData?.medicalInfo?.radioTh, "radio"),
              interpolation: { escapeValue: false },
            }),
          ),
        },
        {
          name: t(`${SettingsNS}:chemotherapy`),
          form: CHEMO_FORM,
          rightChild: renderRightChild(
            t("therapy_data", {
              ns: PatientsListNS,
              therapy_data: therapiesData(userData?.medicalInfo?.chemoTh, "chemo"),
              interpolation: { escapeValue: false },
            }),
          ),
        },
        {
          name: t(`${SettingsNS}:hormoneTherapy`),
          form: HORMONE_FORM,
          rightChild: renderRightChild(
            t("therapy_data", {
              ns: PatientsListNS,
              therapy_data: therapiesData(userData?.medicalInfo?.hormoneTh, "hormone"),
              interpolation: { escapeValue: false },
            }),
          ),
        },
        {
          name: t(`${SettingsNS}:immuno`),
          form: IMMUNO_FORM,
          rightChild: renderRightChild(
            t("therapy_data", {
              ns: PatientsListNS,
              therapy_data: therapiesData(userData?.medicalInfo?.immunoTh, "immuno"),
              interpolation: { escapeValue: false },
            }),
          ),
        },
      ]),
    [
      t,
      renderRightChild,
      therapiesData,
      userData?.medicalInfo?.chemoTh,
      userData?.medicalInfo?.hormoneTh,
      userData?.medicalInfo?.immunoTh,
      userData?.medicalInfo?.radioTh,
    ],
  )

  return <SettingsList listKey="therapiesList" listItems={oncoItems} />
}

const styles = StyleSheet.create({
  rightChildContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
})

export default TherapiesScreen
