import { Body2, Button, firestore, Subtitle1, Subtitle2, useTheme, useUser } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, Platform, StyleSheet, Text, View } from "react-native"

import { ID } from "../common/CommonUserData"
import { Exercise, Phase } from "../common/Phase"
import { collections } from "../common/types"
import currentDateMs from "../features/hooks/useDateMock"
import { itemField } from "../features/hooks/useItemI18n"
import { JourneyNS } from "../features/i18n/constants"
import { getSelectedExos } from "../features/models/PhaseAndSessionFunctions"
import { getFirestoreDate } from "../features/models/Types"
import { Patient } from "../features/models/UserData"
import { updatePatientData } from "../features/models/UserDataFunctions"
import { programContext } from "../features/Providers/ProgramProvider"
import AdditionalDaysModal from "./AdditionalDaysModal"
import CircleProgressComponent from "./CircleProgressComponent"
import ConfirmationModal from "./ConfirmationModal"
import ConfirmPhaseModal from "./ConfirmPhaseModal"
import ExercisesCarousel from "./ExercisesCarousel"
import ExercisesNavigationButtons from "./ExercisesNavigationButtons"

interface ProtocolViewProps {
  patient: Patient
  patientId: ID | undefined
  currentPhase: Phase | undefined
  currentExos: Exercise[]
  isKine: boolean
}

const ProtocolExercicesView: FC<ProtocolViewProps> = ({
  patient,
  patientId,
  currentPhase,
  currentExos,
  isKine,
}) => {
  const {
    fontMaker,
    colors: {
      selection,
      accent,
      grey,
      primary,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()

  const { userData } = useUser()
  const isWeb = Platform.OS === "web"
  const isPatient = userData?.role === "patient"
  const isOrthoOrImplant = useMemo(
    () => patient?.specialty === "ortho" || patient?.specialty === "implant",
    [patient?.specialty],
  )
  const isProgramStarted =
    patient.programStart && dayjs(currentDateMs()).isAfter(getFirestoreDate(patient.programStart))

  const isSurgeryStarted = () => {
    if (!patient.surgeryDate) {
      return false
    }

    const surgeryDate = dayjs(patient.surgeryDate)
    const currentDate = dayjs(currentDateMs())

    return currentDate.isSameOrAfter(surgeryDate) && !currentDate.isSame(surgeryDate, "day")
  }

  const { phases } = useContext(programContext)
  const { t, i18n } = useTranslation(JourneyNS)
  const [loading, setLoading] = useState(true)
  const [totalPhaseDays, setTotalPhaseDays] = useState<number | undefined>(undefined)
  const [daysDone, setDaysDone] = useState<number | null>(null)
  const [selectedPhase, setSelectedPhase] = useState<Phase | undefined>(currentPhase)
  const [upcomingPhase, setUpcomingPhase] = useState<Phase | undefined>()
  const [previousPhase, setPreviousPhase] = useState<Phase | undefined>()
  const [showedExercises, setShowedExercises] = useState<Exercise[]>(currentExos)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)

  const [initialDays, setInitialDays] = useState<number | null>(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [additionalDays, setAdditionalDays] = useState<number>()
  const [warningMessage, setWarningMessage] = useState<string | null>(null)
  const [confirmationMessage, setConfirmationMessage] = useState<string>("")

  const isCurrentSession = useMemo(() => selectedPhase?.id === currentPhase?.id, [
    currentPhase?.id,
    selectedPhase?.id,
  ])
  const currentIndex = useMemo(
    () => phases && phases.findIndex(item => item.id === selectedPhase?.id),
    [phases, selectedPhase],
  )

  useEffect(() => {
    if (!currentIndex && currentIndex !== 0) {
      return
    }
    const phaseToCome = phases && phases[currentIndex + 1]
    const precedingPhase = phases && phases[currentIndex - 1]
    setUpcomingPhase(phaseToCome)
    setPreviousPhase(precedingPhase)
  }, [currentIndex, phases])

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          margin: spacing,
          paddingVertical: spacing,
          backgroundColor: selectedPhase?.isDisabled
            ? grey
            : isCurrentSession && !isPatient
            ? primary
            : accent,
        },
      ],
      scrollViewContainer: [
        styles.nextStepContainer,
        {
          marginTop: isWeb ? spacing * 2.5 : isPatient ? spacing * 2 : spacing,
        },
      ],
      scrollViewTitle: [
        fontMaker({ weight: "Bold" }),
        {
          marginTop: isPatient || !previousPhase || !isCurrentSession ? spacing / 4 : spacing * 2,
        },
      ],
      scrollViewSubtitle: [
        styles.scrollViewSubtitle,
        {
          width: "90%",
          marginTop: isPatient || !previousPhase || !isCurrentSession ? spacing / 2 : spacing,
          paddingHorizontal: spacing,
        },
      ],
      nextStepContainer: [
        styles.nextStepContainer,
        {
          marginTop: spacing,
          paddingHorizontal: spacing,
        },
      ],
      nextStepDate: [
        fontMaker({ weight: "Bold" }),
        {
          marginTop: spacing / 4,
        },
      ],
      textContainer: [
        styles.textContainer,
        {
          paddingTop: spacing,
        },
      ],
    }),
    [
      spacing,
      isWeb,
      fontMaker,
      isPatient,
      previousPhase,
      isCurrentSession,
      accent,
      grey,
      selectedPhase?.isDisabled,
      primary,
    ],
  )

  const calculateInitialDays = (phase: Phase, patientInfo: Patient) =>
    phase.endDay - phase.startDay - (patientInfo.addedDays?.[phase.id] ?? 0) + 1

  const updateProgramDates = async (id: string, programId: string, newProgramEnd: Date) => {
    try {
      if (!programId) {
        throw new Error("Program ID is undefined")
      }

      const programDocRef = firestore()
        .collection(collections.LOGIN)
        .doc(id)
        .collection(collections.PROGRAMS)
        .doc(programId)

      await programDocRef.update({
        end: firestore.Timestamp.fromDate(newProgramEnd),
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error updating program dates: ", err)
    }
  }

  useEffect(() => {
    if (currentPhase && patient) {
      setInitialDays(calculateInitialDays(currentPhase, patient))
    }
  }, [currentPhase, patient])

  const lastPhase = useMemo(() => _.maxBy(phases, "endDay"), [phases])

  useEffect(() => {
    setSelectedPhase(currentPhase)
  }, [currentPhase])

  useEffect(() => {
    setShowedExercises(currentExos)
  }, [currentExos])

  useEffect(() => {
    if (lastPhase?.endDay !== undefined && patient?.surgeryDate) {
      const programEnd = dayjs(patient.surgeryDate).add(lastPhase.endDay, "day").toDate()

      updatePatientData(patient.id, { programEnd })

      if (patient.program) {
        updateProgramDates(patient.id, patient.program, programEnd)
      }
    }
  }, [lastPhase?.endDay, patient?.surgeryDate, patient?.id, patient?.program])

  useEffect(() => {
    if (!modalVisible) {
      setWarningMessage(null)
    }
  }, [modalVisible])

  useEffect(() => {
    if (!currentIndex && currentIndex !== 0) {
      return
    }
    const phaseToCome = phases && phases[currentIndex + 1]
    const precedingPhase = phases && phases[currentIndex - 1]
    setUpcomingPhase(phaseToCome)
    setPreviousPhase(precedingPhase)
  }, [currentIndex, phases])

  const buttonText = useMemo(() => {
    const selectedOrder = selectedPhase?.startDay
    const currentOrder = currentPhase?.startDay

    if (selectedOrder === undefined || currentOrder === undefined) {
      return
    }
    if (selectedOrder === currentOrder) {
      if (selectedPhase?.isDisabled) {
        return t("exercicesView.buttonGoBackPhase")
      } else if (
        patient.addedDays &&
        currentPhase?.id &&
        patient.addedDays[currentPhase.id] &&
        patient.addedDays[currentPhase.id] !== 0 // s'il y a déjà des jours additionnels
      ) {
        return t("exercicesView.buttonEditCurrentPhase")
      } else {
        return t("exercicesView.buttonCurrentPhase")
      }
    }
    if (selectedOrder > currentOrder) {
      return t("exercicesView.buttonNextPhase")
    } else {
      return t("exercicesView.buttonGoBackPhase")
    }
  }, [selectedPhase, t, patient.addedDays, currentPhase])

  const handlePhaseClick = () => {
    const selectedOrder = selectedPhase?.startDay
    const currentOrder = currentPhase?.startDay

    if (selectedOrder === undefined || currentOrder === undefined) {
      return
    }
    if (selectedOrder === currentOrder) {
      if (selectedPhase?.isDisabled) {
        setConfirmModalVisible(true)
      } else {
        // Gestion des jours additionnels dans la phase en cours
        currentPhase?.id && setAdditionalDays(patient.addedDays?.[currentPhase?.id] || 0)
        setModalVisible(true)
      }
    } else if (selectedOrder && currentOrder && selectedOrder > currentOrder) {
      setConfirmModalVisible(true)
    } else if (selectedOrder && currentOrder && selectedOrder < currentOrder) {
      setConfirmModalVisible(true)
    }
  }

  const handleConfirmPhase = async () => {
    if (buttonText === t("exercicesView.buttonGoBackPhase")) {
      await handleConfirmPreviousPhase()
    } else if (buttonText === t("exercicesView.buttonNextPhase")) {
      await handleConfirmNextPhase()
    }
    setConfirmationModal(true)
  }

  const handleConfirmNextPhase = async () => {
    if (currentPhase?.id) {
      await advanceToNextPhase()
      setConfirmationMessage(t("exercicesView.confirmationMessageNextPhase"))
      setConfirmModalVisible(false)
    }
  }

  const handleConfirmPreviousPhase = async () => {
    if (!selectedPhase?.startDay || !patient.surgeryDate) {
      return
    }

    const daysToTargetPhase =
      calculateDaysDone(currentPhase) -
      1 +
      calculateInitialDays(selectedPhase, patient) +
      (patient.addedDays && patient.addedDays[selectedPhase.id]
        ? patient.addedDays[selectedPhase.id]
        : 0)

    const updatedAddedDays = patient.addedDays ? { ...patient.addedDays } : {}

    if (selectedPhase.id in updatedAddedDays) {
      updatedAddedDays[selectedPhase.id] = daysToTargetPhase
    } else {
      updatedAddedDays[selectedPhase.id] = daysToTargetPhase
    }

    if (phases) {
      const sortedPhases = [...phases].sort((a, b) => a.startDay - b.startDay)
      let reset = false
      sortedPhases.forEach(phase => {
        if (phase.id === selectedPhase.id) {
          reset = true
        }
        if (reset && phase.id !== selectedPhase.id && updatedAddedDays[phase.id]) {
          updatedAddedDays[phase.id] = 0
        }
      })
    }

    await updatePatientData(patient.id, {
      addedDays: updatedAddedDays,
      phase: selectedPhase?.id,
      backToPhase: selectedPhase?.id,
      advancePhase: "",
    })
    setConfirmationMessage(t("exercicesView.confirmationMessagePreviousPhase"))
    setConfirmModalVisible(false)
  }

  const handleSelectedPhase = async (isGoBack?: boolean) => {
    if (!patient || !selectedPhase) {
      return
    }
    const newSelectedPhase = isGoBack ? previousPhase : upcomingPhase

    if (!newSelectedPhase) {
      return
    }

    const newPhaseExos = await getSelectedExos(patient, patientId, newSelectedPhase)
    setSelectedPhase(newSelectedPhase)
    setShowedExercises(newPhaseExos)
  }

  const nextPhaseStart =
    currentPhase && (currentPhase?.endDay || currentPhase?.endDay === 0)
      ? firestore.Timestamp.fromDate(
          dayjs(patient?.surgeryDate)
            .add(currentPhase?.endDay + 1, "day")
            .toDate(),
        )
      : undefined
  const formatedNextPhase = nextPhaseStart
    ? dayjs.unix(nextPhaseStart?.seconds).locale(i18n.language).format("DD MMMM YYYY")
    : undefined
  const startDateTimestamp = new Date(patient.programStart?.seconds * 1000)
  const startDate = dayjs(startDateTimestamp).format("DD MMMM YYYY")

  const calculateDaysDone = useCallback(
    phase => {
      const days = phase.endDay - phase.startDay + 1
      setTotalPhaseDays(days)
      const today = dayjs()
      const endDate = dayjs(nextPhaseStart?.toDate())
      const daysLeft = endDate.diff(today, "day")
      return Math.max(days - daysLeft, 1)
    },
    [nextPhaseStart],
  )

  useEffect(() => {
    if (selectedPhase) {
      setLoading(true)
      if (nextPhaseStart) {
        const days = calculateDaysDone(selectedPhase)
        setDaysDone(days)
      }
      setLoading(false)
    }
  }, [selectedPhase, nextPhaseStart, calculateDaysDone, patient.surgeryDate, totalPhaseDays])

  const onChangeText = (text: string) => {
    if (!currentPhase) {
      setWarningMessage(`${t("exercicesView.warningError")}`)
      return
    }

    if (initialDays === null) {
      return
    }

    const maxAdditionalDays = 30

    let textToNumber = 0
    if (text !== "") {
      textToNumber = parseFloat(text)
      if (isNaN(textToNumber)) {
        setWarningMessage(`${t("exercicesView.warningError")}`)
        return
      }
    }

    setAdditionalDays(textToNumber)

    if (textToNumber > maxAdditionalDays) {
      setWarningMessage(`${t("exercicesView.maxAdditionalDaysMessage", { maxAdditionalDays })}`)
    } else {
      const totalDays = initialDays + textToNumber
      if (daysDone !== null && totalDays < daysDone) {
        const minAdditionalDays = daysDone - initialDays
        setWarningMessage(`${t("exercicesView.warningMessage", { minAdditionalDays, daysDone })}`)
      } else {
        setWarningMessage(null)
      }
    }
  }

  const onSubmitAdditionalDays = useCallback(async () => {
    if (!currentPhase?.id) {
      setModalVisible(false)
      return
    }

    if (initialDays === null) {
      return
    }

    const safeAdditionalDays = additionalDays ?? 0
    const totalDays = initialDays + safeAdditionalDays

    if (daysDone !== null && totalDays < daysDone) {
      const minAdditionalDays = daysDone - initialDays
      setWarningMessage(`${t("exercicesView.warningMessage", { minAdditionalDays, daysDone })}`)
    } else if (additionalDays && additionalDays > 30) {
      setWarningMessage(`${t("exercicesView.maxAdditionalDaysMessage", { maxAdditionalDays: 30 })}`)
    } else {
      const updatedAddedDays = patient.addedDays || {}
      updatedAddedDays[currentPhase.id] = safeAdditionalDays
      const updateData = { addedDays: updatedAddedDays }

      try {
        await updatePatientData(patient.id, updateData)
        setConfirmationMessage(
          `${t("exercicesView.confirmationMessageAdditionalDays", {
            safeAdditionalDays,
            initialDays,
            totalDays,
          })}`,
        )
        setModalVisible(false)
        setConfirmationModal(true) // Afficher la popup de confirmation
      } catch (err) {
        setWarningMessage(`${t("exercicesView.warningError")}`)
      }
    }
  }, [patient.id, patient.addedDays, currentPhase?.id, additionalDays, daysDone, t, initialDays])

  const advanceToNextPhase = async () => {
    if (currentPhase && selectedPhase) {
      const daysInCurrentPhase = currentPhase.endDay - currentPhase.startDay + 1
      const daysLeftInCurrentPhase = daysInCurrentPhase - calculateDaysDone(currentPhase) + 1
      const updatedAddedDays = patient.addedDays ? { ...patient.addedDays } : {}
      const phasesInBetween = phases?.filter(
        ph => ph.startDay > currentPhase.endDay && ph.endDay < selectedPhase.startDay,
      )

      phasesInBetween?.forEach(ph => {
        const totalDaysInPhase = ph.endDay - ph.startDay + 1
        if (ph.id in updatedAddedDays) {
          updatedAddedDays[ph.id] -= totalDaysInPhase
        } else {
          updatedAddedDays[ph.id] = -totalDaysInPhase
        }
      })

      if (currentPhase.id in updatedAddedDays) {
        updatedAddedDays[currentPhase.id] -= daysLeftInCurrentPhase
      } else {
        updatedAddedDays[currentPhase.id] = -daysLeftInCurrentPhase
      }
      await updatePatientData(patient.id, {
        addedDays: updatedAddedDays,
        phase: selectedPhase.id,
        advancePhase: selectedPhase.id,
        backToPhase: "",
      })
    }
  }

  return (
    <View style={s.container}>
      {isCurrentSession && isProgramStarted ? (
        loading ? (
          <ActivityIndicator size="large" color={selection} />
        ) : (
          <CircleProgressComponent
            daysDone={daysDone}
            totalDays={totalPhaseDays}
            isPatient={isPatient}
            isPreviousPhase={previousPhase}
          />
        )
      ) : null}
      <ExercisesNavigationButtons
        previousPhase={previousPhase}
        upcomingPhase={upcomingPhase}
        handleSelectedPhase={handleSelectedPhase}
        isPatient={isPatient}
        isCurrentSession={isCurrentSession}
      />
      <View style={s.scrollViewContainer}>
        {isCurrentSession ? (
          <>
            <Subtitle1 style={[s.scrollViewTitle, !isPatient && { color: white }]}>
              {isPatient
                ? `${t("exercicesView.currentExos")}`
                : `${t("exercicesView.currentPhase")}`}
            </Subtitle1>
          </>
        ) : (
          <Subtitle1 style={s.scrollViewTitle}>
            {isPatient
              ? t("exercicesView.exosTocome")
              : `${t("exercicesView.kinePhase")} ${
                  currentIndex !== undefined && currentIndex !== null ? currentIndex + 1 : ""
                } ${
                  totalPhaseDays === 0
                    ? ""
                    : `(${totalPhaseDays} ${
                        totalPhaseDays === 1
                          ? t("exercicesView.totalDay")
                          : t("exercicesView.totalDays")
                      })`
                }`}
          </Subtitle1>
        )}
        {!isPatient ? (
          <Subtitle2 style={[s.scrollViewSubtitle, isCurrentSession && { color: white }]}>
            {itemField(selectedPhase, "objective", t)}
          </Subtitle2>
        ) : null}
        {selectedPhase ? (
          !isPatient ? (
            <Body2
              style={[
                s.textContainer,
                fontMaker({ weight: "Bold" }),
                isCurrentSession && { color: white },
              ]}
            >
              {selectedPhase.isDisabled
                ? isKine
                  ? t("exercicesView.disabledKine")
                  : t("exercicesView.disabled")
                : patient.advancePhase === selectedPhase?.id &&
                  currentPhase?.id === selectedPhase?.id
                ? isKine
                  ? t("exercicesView.advanceToNextPhaseKine")
                  : t("exercicesView.advanceToNextPhase")
                : patient.backToPhase === currentPhase?.id && currentPhase?.id === selectedPhase?.id
                ? isKine
                  ? t("exercicesView.goBackToPhaseMessageKine")
                  : t("exercicesView.goBackToPhaseMessage")
                : null}
            </Body2>
          ) : (
            <Body2 style={[s.textContainer, fontMaker({ weight: "Bold" })]}>
              {patient.backToPhase === currentPhase?.id && currentPhase?.id === selectedPhase?.id
                ? t("exercicesView.infoPatientGoBackToPhaseMessage")
                : patient.advancePhase === selectedPhase?.id &&
                  currentPhase?.id === selectedPhase?.id
                ? t("exercicesView.infoPatientAdvancePhase")
                : patient.addedDays && patient.addedDays[selectedPhase.id] > 0
                ? t("exercicesView.addedDaysInfoPatient", {
                    addedDaysNumber: patient.addedDays[selectedPhase.id],
                  })
                : ""}
            </Body2>
          )
        ) : null}
        <ExercisesCarousel
          isCurrentSession={isCurrentSession}
          isPatient={isPatient}
          patientId={patientId}
          showedExercises={showedExercises}
        />
      </View>

      <View style={s.nextStepContainer}>
        {isPatient ? (
          currentPhase?.id === selectedPhase?.id ? (
            <Subtitle2 style={s.textContainer}>
              {!isProgramStarted
                ? t("exercicesView.programToCome")
                : !upcomingPhase
                ? t("exercicesView.noNextSession")
                : t("exercicesView.nextSession")}
              <Text style={s.nextStepDate}>
                {" "}
                {!isProgramStarted ? startDate : formatedNextPhase}
              </Text>
            </Subtitle2>
          ) : null
        ) : selectedPhase?.startDay &&
          selectedPhase?.startDay >= 1 &&
          isSurgeryStarted() && // attendre que la date de chirurgie soit passée avant de pouvoir adapter le protocole
          !(
            selectedPhase &&
            currentPhase &&
            (selectedPhase?.startDay < currentPhase?.startDay || selectedPhase.isDisabled) &&
            phases &&
            currentPhase &&
            phases.findIndex(ph => ph.id === currentPhase.id) - 1 !== currentIndex
          ) ? (
          <>
            {isOrthoOrImplant && isKine && (
              <Button
                mode="contained"
                buttonColor={isCurrentSession ? accent : primary}
                contentStyle={{ backgroundColor: isCurrentSession ? accent : primary }}
                labelStyle={{ color: isCurrentSession ? primary : accent }}
                onPress={handlePhaseClick}
              >
                {buttonText}
              </Button>
            )}
          </>
        ) : null}
      </View>
      <AdditionalDaysModal
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        onChangeText={onChangeText}
        onSubmit={onSubmitAdditionalDays}
        additionalDays={additionalDays}
        initialDays={initialDays}
        totalPhaseDays={totalPhaseDays}
        warningMessage={warningMessage}
        patient={patient}
        currentPhase={currentPhase}
        selectedPhase={selectedPhase}
      />
      <ConfirmPhaseModal
        visible={confirmModalVisible}
        onRequestClose={() => setConfirmModalVisible(false)}
        onConfirm={handleConfirmPhase}
      />
      <ConfirmationModal
        visible={confirmationModal}
        onRequestClose={() => setConfirmationModal(false)}
        confirmationMessage={confirmationMessage}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 6,
  },
  nextStepContainer: {
    alignItems: "center",
  },
  textContainer: {
    paddingTop: 10,
    alignItems: "center",
    textAlign: "center",
    lineHeight: 20,
    width: "80%",
  },
  scrollViewSubtitle: {
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
})

export default ProtocolExercicesView
