import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types"
import { IUserContext, openUrl, userContext, useTheme } from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { Checkbox, List } from "react-native-paper"
import { RootParamList } from "src/features/Navigation/types"

import { ItemList } from "../common/List"
import { collections } from "../common/types"
import { urls } from "../features/config/Constants"
import { itemField } from "../features/hooks/useItemI18n"
import { PatientPromsResult } from "../features/models/Proms"
import { AppUserData } from "../features/models/UserData"
import { updateUserData } from "../features/models/UserDataFunctions"
import { promsContext } from "../features/Providers/PromsProvider"
import PhaseFaqVideoView from "../screens/PhaseResumeScreen/PhaseFaqVideoView"

interface IProps {
  item: ItemList
}

const getFormId = (url: string) => {
  if (_.includes(url, urls.PROMS)) {
    return _.replace(url, urls.PROMS, "")
  }
  return undefined
}

const ListItem: FC<IProps> = ({ item }) => {
  const {
    colors: {
      primary,
      black: { highEmphasis: black },
    },
    typography: { body2 },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation<StackNavigationProp<RootParamList, "PromsNavigator">>()
  const { loadForm } = useContext(promsContext)
  const { userData, userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const formId = getFormId(item.url)
  const title = itemField(item, "title", t)
  const description = itemField(item, "description", t)
  const [expanded, setExpanded] = useState(false)
  const triggerDayTag = item.tag?.match(/\d+/)

  const prefix = "https://doctup.page.link/"

  const extractUrlSuffix = url => (url.startsWith(prefix) ? url.substring(prefix.length) : null)

  const itemWithExtractedUrl = {
    ...item,
    extractedUrl: item.url?.startsWith(prefix) ? extractUrlSuffix(item.url) : null,
  }
  const [userProms] = useCollectionData<PatientPromsResult & { docId: string }>(
    userDocRef && formId
      ? ((userDocRef
          .collection(collections.PROMS)
          .where("id", "==", formId) as unknown) as firebase.firestore.Query<PatientPromsResult>)
      : null,
    { idField: "docId" },
  )
  const lastUserProms = useMemo(() => {
    const filteredArray = userProms?.filter(proms => proms.triggerDay !== undefined)
    if (!filteredArray || filteredArray.length === 0) {
      return userProms?.[0]
    }
    return filteredArray.reduce(
      (maxObj, obj) =>
        obj.triggerDay &&
        (!maxObj || maxObj.triggerDay === undefined || obj.triggerDay > maxObj.triggerDay)
          ? obj
          : maxObj,
      null as (PatientPromsResult & { docId: string }) | null,
    )
  }, [userProms])
  const triggerDay = triggerDayTag ? parseInt(triggerDayTag[0], 10) : undefined
  const docId = lastUserProms ? lastUserProms.docId : undefined
  const isShowAgain = lastUserProms ? lastUserProms.showAgain : undefined

  const isChecked = useMemo(() => {
    const doneTaskChecked = userData?.doneTasks?.[item.id]
    const extractedUrlChecked = userProms?.some(
      proms => proms.id === itemWithExtractedUrl.extractedUrl && proms.showAgain === false,
    )
    return doneTaskChecked || extractedUrlChecked
  }, [userData, item.id, userProms, itemWithExtractedUrl.extractedUrl])

  const status = isChecked ? "checked" : "unchecked"
  const s = useMemo(
    () => ({
      itemView: [
        styles.itemView,
        {
          paddingLeft: 0,
          // specific due to left and right icon that have default margins
          // different from our design system
          marginHorizontal: spacing * 2.6,
          marginBottom: spacing / 2,
        },
      ],
      header: {
        color: black,
        textDecorationLine: isChecked ? "line-through" : undefined,
      },
      body: [
        body2,
        styles.body,
        {
          color: black,
          marginRight: spacing * 2,
        },
      ],
      button: [
        body2,
        styles.body,
        {
          color: primary,
        },
      ],
      rightIcon: [
        styles.rightIcon,
        {
          marginTop: expanded ? 0 : null,
        },
      ],
      description: {
        paddingVertical: spacing,
      },
    }),
    [black, body2, expanded, isChecked, primary, spacing],
  )

  const onPress = useCallback(() => {
    // @ts-ignore
    // noinspection JSIgnoredPromiseFromCall
    updateUserData(userDocRef, {
      doneTasks: {
        [item.id]: !isChecked,
      },
    })
  }, [isChecked, item.id, userDocRef])

  const left = useCallback(
    () => (
      <View style={styles.checkbox}>
        {userData?.role === "patient" ? (
          <Checkbox.Android {...{ onPress, status }} color={primary} />
        ) : null}
      </View>
    ),
    [primary, onPress, status, userData?.role],
  )

  const onExpanded = useCallback(() => {
    setExpanded(prev => !prev)
  }, [])

  const onOpenUrl = useCallback(() => {
    if (formId) {
      loadForm?.({ id: formId, docId, isTodo: true, triggerDay, isShowAgain }).then(formPresent => {
        if (formPresent) {
          navigation.navigate("PromsNavigator")
        }
      })
    } else {
      openUrl(item.url)
    }
  }, [formId, item.url, loadForm, navigation, docId, isShowAgain, triggerDay])

  return (
    <List.Accordion
      {...{ title, left }}
      onPress={onExpanded}
      // @ts-ignore new custom prop to control item box
      titleStyle={s.header}
      titleNumberOfLines={2}
      descriptionNumberOfLines={200}
      itemStyle={[styles.accordionItem]}
      description={expanded ? description : undefined}
      descriptionStyle={s.description}
      rightStyle={s.rightIcon}
    >
      <PhaseFaqVideoView item={item} onOpenUrl={onOpenUrl} />
    </List.Accordion>
  )
}

const styles = StyleSheet.create({
  accordionItem: {
    marginTop: 0,
    marginBottom: 0,
    alignSelf: "flex-start",
    paddingTop: 6,
  },
  itemBody: {
    marginTop: 0,
  },
  itemView: {
    padding: 0,
  },
  body: {
    lineHeight: 16,
  },
  checkbox: {
    alignSelf: "flex-start",
    marginTop: 0,
  },
  rightIcon: {
    alignSelf: "flex-start",
  },
})

export default ListItem
