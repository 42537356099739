export const ICON_SIZE = 24
export const ICON_SIZE_SMALL = 16
export const ICON_SIZE_MEDIUM = 32
export const BOTTOM_SIZE = 56
export const SCROLL_HEIGHT = 64
export const TEXT_LIMIT = 256
export const INPUT_HEIGHT = 52
export const CIRCLE_DEFAULT_SIZE = 36

export const urls = {
  HELP: "https://www.doctup.fr/",
  PRIVACY: "https://www.doctup.fr/mentions-l%C3%A9gales",
  TERMS_OF_USE: "https://www.doctup.fr/gcv-cgu",
  CUSTOMER_SUPPORT: "mailto:contact@doctup.fr",
  FAQ: "https://www.doctup.fr/", // TODO: replace by correct url
  REVIEW: "https://support.google.com/business/answer/7035772?hl=en",
  PROMS: "https://doctup.page.link/",
}

export const JAUGE_SCORE = "evaj"
export const RATING_SCORE = "rating"
export const RATING_FORM = "FOR003"
export const PATIENT_SURVEY_FORM = "FOR313"
export const CANCER_DATA_FORM = "FORONCO001"
export const ONCO_SURGERY_FORM = "FORONCO002"
export const CHEMO_FORM = "FORONCO003"
export const RADIO_FORM = "FORONCO004"
export const HORMONE_FORM = "FORONCO005"
export const IMMUNO_FORM = "FORONCO006"
export const OTHER_PATH_FORM = "FORONCO007"
export const METASTATIC_FORM = "FORONCO008"
export const SURGERY_SETTINGS = "FORONCO009"

// Champs spécifiques Smith & Nephew
export const KNEE_PROSTHESIS_PROTOCOLS = ["pat003", "pat004"]
export const SMITH_NEPHEW_PARTNER = "PAR008"

export const TECHNICAL_FAQ = "faq000"

export const DoctupSupportedCountries = [
  { countryCode: "FR", countryName: "France", countryCallingCode: "+33" },
  { countryCode: "CH", countryName: "Suisse", countryCallingCode: "+41" },
]

export const FR_CALENDAR_CONFIG = {
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Janv.",
    "Févr.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc.",
  ],
  dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  dayNamesShort: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
  today: "Aujourd'hui",
}

export const EN_CALENDAR_CONFIG = {
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  today: "Today",
}
