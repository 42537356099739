import dayjs from "dayjs"

export const MINUTE_MS = 60000
export const ISO8601_TIME = "HH:mm"

export const convertDecimalHour = (time: number) => {
  const date = new Date(time)
  return `${dayjs(date).get("hours")}:${dayjs(date).get("minutes")}`
}

export const getUtcTime = (value: string) => dayjs(value, ISO8601_TIME).utc(true)

export const daySinceSurgery = (date: Date | string | undefined) => {
  if (!date) {
    return undefined
  }

  const surgeryDate = dayjs(date).startOf("day")
  const currentDate = dayjs().startOf("day")

  return currentDate.diff(surgeryDate, "days")
}

export const getIsoTime = () =>
  dayjs()
    .localeData()
    .longDateFormat("LTS")
    .replace(/[H|h]?[H|h]./g, "")
    .replace("A", "")

export const convertSecondsToIsoTime = (total: number) => {
  const minutes = Math.floor(total / 60)
  const seconds = total % 60
  return dayjs(`${minutes}:${seconds}}`, "mm:ss").format(getIsoTime())
}

export const getDatesFrom = (numberOfDays: number) => {
  const dates: Date[] = []
  for (let i = 0; i < Math.abs(numberOfDays); i++) {
    dates.push(
      new Date(new Date().getTime() - (numberOfDays >= 0 ? i : i - i - i) * 24 * 60 * 60 * 1000),
    )
  }
  return dates
}

export const getFlagEmoji = (countryCode: string) =>
  String.fromCodePoint(...[...countryCode.toUpperCase()].map(char => 127397 + char.charCodeAt(0)))
