import {
  DateTimePicker,
  FormikCheckbox,
  IUserContext,
  userContext,
  useTheme,
  VectorIconProps,
} from "capsule"
import dayjs from "dayjs"
import { useFormikContext } from "formik"
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { Question } from "../common/Proms"
import { ICON_SIZE, INPUT_HEIGHT } from "../features/config/Constants"
import currentDateMs from "../features/hooks/useDateMock"
import { itemField } from "../features/hooks/useItemI18n"
import { AppUserData } from "../features/models/UserData"
import { promsContext } from "../features/Providers/PromsProvider"

interface IProps {
  item: Question
  isQuestion?: boolean
  setIsButtonDisabled: Dispatch<SetStateAction<boolean>>
  currentValue?: string | number | boolean | string[]
}

const DateAndCheck: FC<IProps> = ({ item, setIsButtonDisabled, isQuestion, currentValue }) => {
  const {
    colors: { black, surface },
    dimensions: { spacing },
  } = useTheme()

  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<boolean | string>()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const { form } = useContext(promsContext)
  const isEditForm = form?.isEdit
  const itemName = item.name
  const currentDate = new Date(currentDateMs())

  useEffect(() => {
    if (isEditForm && form && form.dataGroupName && userData?.medicalInfo) {
      if (userData.medicalInfo[form.dataGroupName][itemName]) {
        const userDate = dayjs(userData.medicalInfo[form.dataGroupName][itemName].toDate())
        const formattedDate = userDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        setFieldValue(itemName, dayjs(formattedDate).toDate())
      } else if (userData.medicalInfo[form.dataGroupName][`${itemName}_unknown`]) {
        setFieldValue(
          `${itemName}_unknown`,
          userData.medicalInfo[form.dataGroupName][`${itemName}_unknown`],
        )
      }
    }
  }, [form, isEditForm, itemName, setFieldValue, userData?.medicalInfo])

  useEffect(() => {
    setIsButtonDisabled(!values[itemName] && !values[`${itemName}_unknown`])
    if (values[`${itemName}_unknown`]) {
      setFieldValue(itemName, null)
    }
  }, [values, itemName, setFieldValue, setIsButtonDisabled])

  const dateType = useCallback(() => {
    switch (currentValue) {
      case "to_come":
        return "start-date"
      case "in_progress":
      case "ended":
        return "end-date"
      default:
        return undefined
    }
  }, [currentValue])

  const renderIcon = useCallback(
    (name: string) =>
      ({
        name,
        category: "MaterialIcons",
        size: ICON_SIZE,
        color: black.highEmphasis,
      } as VectorIconProps),
    [black.highEmphasis],
  )

  const s = useMemo(
    () => ({
      mainView: [
        styles.mainView,
        {
          backgroundColor: surface.background,
        },
      ],
      label: {
        marginVertical: spacing / 2.5,
      },
      checkbox: [
        styles.checkbox,
        {
          marginTop: spacing / 2,
        },
      ],
      touchable: [styles.touchable, { backgroundColor: surface.textInput }],
    }),
    [spacing, surface.background, surface.textInput],
  )

  return (
    <View style={isQuestion ? null : s.mainView}>
      <DateTimePicker
        mode="date"
        name={itemName}
        touchableStyle={s.touchable}
        label={itemField(item, "title", t)}
        icons={{
          left: renderIcon("calendar-today"),
          right: renderIcon("arrow-drop-down"),
        }}
        dateType={dateType()}
        initialValue={values[itemName] ? values[itemName] : currentDate}
        isChecked={values[`${itemName}_unknown`]}
      />
      <FormikCheckbox
        key={`${itemName}_unknown`}
        name={`${itemName}_unknown`}
        label={t("common:button.dateCheckbox")}
        showDefault={true}
        contentStyle={s.checkbox}
        styles={s}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
  },
  touchable: {
    height: INPUT_HEIGHT,
    borderRadius: 0,
  },
  checkbox: {
    alignSelf: "flex-start",
    flexDirection: "row",
  },
})

export default DateAndCheck
