import {
  firestore,
  IRowItemProps,
  ISectionHeader,
  IUserContext,
  ParametersScreen,
  PresentationListView,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import _ from "lodash"
import React, { useContext, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { SectionListData, StyleSheet } from "react-native"
import FastImage from "react-native-fast-image"

import { Specialty } from "../../common/Pathology"
import { collections } from "../../common/types"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import useRightChild from "../../features/hooks/useRightChild"
import { AppUserData } from "../../features/models/UserData"
import { OperationParamList } from "../OperationScreen/OperationNavigator"

const SpecialitiesScreen = () => {
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const {
    colors: {
      white: { highEmphasis: white },
      primary,
    },
    dimensions: { spacing },
    typography: { subtitle1 },
  } = useTheme()
  const [specialties, loading, error] = useCollectionData<Specialty>(
    (firestore()
      .collection(collections.SPECIALTIES)
      .where(
        "name",
        "in",
        userData?.specialties,
      ) as unknown) as firebase.firestore.Query<Specialty>,
  )

  const { t } = useTranslation()

  const s = useMemo(
    () => ({
      image: [
        styles.image,
        {
          margin: spacing,
          marginRight: spacing,
        },
      ],
      row: [
        styles.row,
        {
          backgroundColor: white,
        },
      ],
      indicator: { color: primary, size: ICON_SIZE },
    }),
    [spacing, white, primary],
  )
  const rightChild = useRightChild()
  const specialtiesData = _(specialties)
    .map(
      specialty =>
        ({
          rightChild,
          leftChild: <FastImage style={s.image} source={{ uri: specialty.image }} />,
          name: itemField(specialty, "title", t),
          rowStyle: s.row,
          textStyle: subtitle1,
          route: "Organ",
          params: { specialtyId: specialty.name },
        } as IRowItemProps<OperationParamList>),
    )
    .value()

  const specialtySectionData: Array<
    SectionListData<IRowItemProps<OperationParamList>, ISectionHeader>
  > = [
    {
      shadows: 1,
      data: specialtiesData,
      title: "",
    },
  ]

  return (
    <PresentationListView
      isEmpty={_.isEmpty(specialties)}
      indicator={s.indicator}
      loading={loading}
      error={error}
    >
      <ParametersScreen listKey="specialtyList" sections={specialtySectionData} />
    </PresentationListView>
  )
}

const styles = StyleSheet.create({
  image: {
    width: INPUT_HEIGHT,
    height: INPUT_HEIGHT,
  },
  row: {
    height: 80,
  },
})

export default SpecialitiesScreen
