import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  BottomButtonContainer,
  generateShadow,
  H6,
  SectionSeparator,
  Subtitle2,
  useTheme,
} from "capsule"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, StyleSheet, View } from "react-native"

import SubmitButton from "../../components/SubmitButton"
import useHomeActions from "../../features/hooks/useHomeActions"
import { HomeNS } from "../../features/i18n/constants"
import { RootParamList } from "../../features/Navigation/types"
import { programContext } from "../../features/Providers/ProgramProvider"
import { promsContext } from "../../features/Providers/PromsProvider"

const HomePromsByIdView = ({ formId }) => {
  const { t } = useTranslation(HomeNS)
  const { loadForm } = useContext(promsContext)
  const { currentPhase } = useContext(programContext)
  const navigation = useNavigation<StackNavigationProp<RootParamList, "PromsNavigator">>()
  const { getFormById } = useContext(promsContext)
  const [formById, setFormById] = useState<any>(undefined)
  const { initialLoading } = useHomeActions()
  const {
    dimensions: { spacing },
    colors: {
      white: { highEmphasis: white },
      surface: { background },
    },
  } = useTheme()
  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          backgroundColor: background,
        },
      ],
      indicator: {
        marginTop: spacing * 2,
      },
      ratingContainer: [
        styles.ratingContainer,
        {
          backgroundColor: white,
          padding: spacing,
          margin: spacing,
        },
        generateShadow(2),
      ],
      bottomButtonContainer: [styles.bottomButtonContainer],
    }),
    [spacing, white, background],
  )

  const onSubmit = useCallback(() => {
    loadForm?.({
      id: formId,
      isTodo: true,
      triggerDay: currentPhase?.startDay,
      isShowAgain: true,
    }).then(formPresent => {
      if (formPresent) {
        navigation.navigate("PromsNavigator")
      }
    })
  }, [loadForm, navigation, formId, currentPhase?.startDay])

  useEffect(() => {
    const getForm = async () => {
      const form = await getFormById(formId)
      setFormById(form)
    }
    getForm()
  }, [getFormById, formId])

  return (
    <View>
      {initialLoading ? (
        <ActivityIndicator style={s.indicator} size="large" />
      ) : (
        <>
          {formById ? (
            <View style={s.container}>
              <SectionSeparator />
              <View style={s.ratingContainer}>
                <H6>{formById.title}</H6>
                <Subtitle2>{formById.i18n.fr.description}</Subtitle2>
                <BottomButtonContainer style={s.bottomButtonContainer}>
                  <SubmitButton
                    label={t("fillSatisfaction")}
                    viewStyle={styles.bottomButton}
                    {...{ onSubmit }}
                  />
                </BottomButtonContainer>
              </View>
              <SectionSeparator />
            </View>
          ) : null}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  ratingContainer: {
    borderRadius: 20,
  },
  iconView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 15,
  },
  bottomButtonContainer: {
    justifyContent: "center",
  },
  bottomButton: {
    width: "100%",
  },
})

export default HomePromsByIdView
