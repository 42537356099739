import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { Button, generateShadow, H4, useTheme, useUser } from "capsule"
import _ from "lodash"
import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SafeAreaView, StatusBar, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import ExercicesList from "../../components/ExercisesList"
import { itemField } from "../../features/hooks/useItemI18n"
import useSpecialtySystem from "../../features/hooks/useSpecialtySystem"
import { HomeNS } from "../../features/i18n/constants"
import { programContext } from "../../features/Providers/ProgramProvider"
import { sessionContext } from "../../features/Providers/SessionProvider"
import { ExerciseParamList } from "../ExerciseScreen/ExerciseNavigator"

const ExercisesListScreen = () => {
  const {
    colors: {
      primary,
      surface: { background },
      black: { highEmphasis: black, inactive },
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation<
    StackNavigationProp<ExerciseParamList, "Exercise" | "MorningEvaluation">
  >()
  const { userData } = useUser()
  const { startSession } = useContext(sessionContext)
  const { isSessionReady, currentSession, phaseExercises, phaseEquipments } = useContext(
    programContext,
  )
  const { displayEvaluation } = useSpecialtySystem()
  const equipmentsText = useMemo(
    () =>
      _.join(
        _(phaseExercises)
          .flatMap(ex => ex.equipment)
          .uniq()
          .map(id => itemField(phaseEquipments?.[id], "title", t))
          .value(),
        " • ",
      ),
    [phaseEquipments, phaseExercises, t],
  )

  const s = useMemo(
    () => ({
      safeView: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      headerView: {
        padding: spacing,
        backgroundColor: primary,
      },
      title: {
        color: white,
        paddingBottom: spacing * 2,
      },
      button: [styles.button, generateShadow(4)],
      label: {
        color: isSessionReady ? black : white,
      },
      contentButton: [
        styles.contentButton,
        {
          backgroundColor: isSessionReady ? white : inactive,
        },
      ],
      indicator: { paddingTop: spacing * 2 },
    }),
    [isSessionReady, black, background, inactive, primary, spacing, white],
  )

  const onPress = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    startSession()
    navigation.navigate(displayEvaluation(false) ? "MorningEvaluation" : "Exercise", {
      endProgram: false,
    })
  }, [displayEvaluation, navigation, startSession])

  return currentSession ? (
    <SafeAreaView style={s.safeView}>
      <StatusBar barStyle="dark-content" backgroundColor={primary} />
      <View style={s.headerView}>
        <H4 style={s.title}>{t("session", { time: currentSession.slot, ns: HomeNS })}</H4>
        <Button
          {...{ onPress }}
          mode="outlined"
          disabled={!isSessionReady}
          style={s.button}
          labelStyle={s.label}
          contentStyle={s.contentButton}
        >
          {t(isSessionReady ? "start" : "comeBack", {
            ns: HomeNS,
            slot: isSessionReady ? undefined : currentSession?.slot,
          })}
        </Button>
      </View>
      <ExercicesList
        isScreen={true}
        exercises={phaseExercises}
        specialty={userData?.specialty}
        {...{ currentSession, equipmentsText }}
      />
    </SafeAreaView>
  ) : (
    <ActivityIndicator style={s.indicator} size="large" />
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  contentButton: {
    height: 48,
  },
  button: {
    borderRadius: 100,
  },
})

export default ExercisesListScreen
