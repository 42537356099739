import { firestore } from "capsule"
import firebase from "firebase"
import { TFunction } from "i18next"
import _ from "lodash"
import { useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"

import { BaseData, Pathology } from "../../common/Pathology"
import { Exercise } from "../../common/Phase"
import { CollectionNames } from "../../common/types"

export const itemName = (item: BaseData<"name">, t: TFunction) =>
  item?.i18n?.[t("common:lang")]?.name ?? item?.i18n?.fr?.name ?? t("common:unknown.name")

export const itemField = <Type extends BaseData>(
  item: Type | undefined,
  field: keyof Type["i18n"]["fr"],
  t: TFunction,
) =>
  nullIfEmpty(item?.i18n?.[t("common:lang")]?.[field]) ??
  // @ts-ignore
  nullIfEmpty(item?.i18n?.fr?.[field]) ??
  ""

const nullIfEmpty = s => (_.isEmpty(s) ? undefined : s)

type UseItemI18nType = {
  <Type extends BaseData>(
    collection: CollectionNames,
    id: string,
    field: keyof Type["i18n"]["fr"],
  ): string
  (collection: CollectionNames, id: string, field: keyof BaseData["i18n"]["fr"]): string
  (collection: "exercises", id: string, field: keyof Exercise["i18n"]["fr"]): string
  (collection: "pathologies", id: string, field: keyof Pathology["i18n"]["fr"]): string
}

const useItemI18n: UseItemI18nType = <Type extends BaseData>(
  collection: CollectionNames,
  id: string,
  field: keyof Type["i18n"]["fr"],
) => {
  const { t } = useTranslation()
  const [item] = useDocumentData<Type>(
    id
      ? ((firestore()
          .collection(collection)
          .doc(id) as unknown) as firebase.firestore.DocumentReference)
      : undefined,
  )
  return useMemo(() => (item ? itemField<Type>(item, field, t) : undefined), [field, item, t])
}

export default useItemI18n
