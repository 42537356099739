import { IUserContext, logger, Subtitle1, userContext, useTheme } from "capsule"
import _ from "lodash"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import SelectDropdown from "react-native-select-dropdown"
import FontAwesome from "react-native-vector-icons/FontAwesome"

import { LanguageNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { maxWidth } from "../../ThemeApp"
import { getFlagEmoji } from "../../utils/conversion"
import { useTranslate } from "../../utils/useTranslate"
import { LanguageSelector } from "./Constants"

export interface LanguageList {
  code: string
  title: string
  flag: string
}

const LanguageScreen = () => {
  const { t, i18n } = useTranslation(LanguageNS)
  const {
    dimensions: { spacing, margin },
    colors: {
      surface: { background, textInput },
    },
  } = useTheme()
  const { userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const { setLanguage } = useTranslate()

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          backgroundColor: background,
          padding: spacing,
        },
      ],
      icon: {
        marginLeft: spacing,
      },
      screenStyle: [Platform.OS === "web" ? { width: maxWidth } : null],
      selectorContainer: [
        styles.phoneInputContainer,
        {
          margin,
        },
      ],
      text: {
        marginLeft: margin,
      },
    }),
    [background, spacing, margin],
  )

  const languageArray = useMemo(
    () =>
      _.map(LanguageSelector, country => ({
        code: country.languageCode,
        title: t(`name.${country.languageCode}`),
        flag: getFlagEmoji(country.languageFlag),
      })),
    [t],
  )

  const selectedLng = _.find(languageArray, l => l.code === i18n.language)

  const handleSelect = async selectedItem => {
    try {
      updateUserData(userDocRef, { lng: selectedItem.code })
      setLanguage(selectedItem.code)
    } catch (error) {
      logger("Error when saving the langage", error)
    }
  }

  const WebSelectDropdown = ({ data, defaultValue, onSelect }) => (
    <select
      value={defaultValue.code}
      onChange={e => {
        // @ts-ignore
        const selectedCode = e.target.value
        const selectedItem = data.find(item => item.code === selectedCode)
        onSelect(selectedItem)
      }}
      style={{
        width: "30%",
        padding: 8,
        backgroundColor: textInput,
        border: "none",
        fontFamily: "Montserrat",
      }}
    >
      {data.map(item => (
        <option key={item.code} value={item.code}>
          {`${item.flag} ${item.title}`}
        </option>
      ))}
    </select>
  )

  return (
    <View style={s.container}>
      <View style={s.screenStyle}>
        <Subtitle1 style={s.text}>{t("title")}</Subtitle1>
        <View style={s.selectorContainer}>
          {Platform.OS === "web" ? (
            <WebSelectDropdown
              data={languageArray}
              defaultValue={selectedLng}
              onSelect={handleSelect}
            />
          ) : (
            <SelectDropdown
              data={languageArray}
              defaultValue={selectedLng}
              buttonStyle={styles.langageButton}
              buttonTextAfterSelection={selectedItem =>
                `${selectedItem.flag} ${selectedItem.title}`
              }
              onSelect={selectedItem => {
                handleSelect(selectedItem)
              }}
              rowTextForSelection={item => `${item.flag} ${item.title}`}
              renderDropdownIcon={isOpened => (
                <View style={s.icon}>
                  <FontAwesome
                    name={isOpened ? "chevron-up" : "chevron-down"}
                    color="#444"
                    size={18}
                  />
                </View>
              )}
              dropdownIconPosition="right"
              dropdownStyle={styles.dropdownButton}
              buttonTextStyle={styles.textStyle}
              rowTextStyle={styles.textStyle}
            />
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  phoneInputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  langageButton: {
    flexBasis: "60%",
    backgroundColor: "rgba(33, 33, 33, 0.05)",
  },
  dropdownButton: {
    width: "50%",
  },
  textStyle: {
    fontFamily: "Montserrat",
  },
})

export default LanguageScreen
