import { H6, Subtitle1, useTheme } from "capsule"
import React, { FC, useEffect, useMemo, useState } from "react"
import { Dimensions, StyleSheet, View } from "react-native"

interface TimelineProps {
  activityTracking: string[]
}
const { width } = Dimensions.get("window")

const HorizontalCalendarTimeline: FC<TimelineProps> = ({ activityTracking }) => {
  const {
    fontMaker,
    colors: {
      accent,
      secondary,
      black: { strong, mediumEmphasis },
      grey,
    },
    dimensions: { spacing },
    typography: { body1, dateNumber },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: {
        marginTop: spacing,
      },
      timelineContainer: [
        styles.timelineContainer,
        {
          marginVertical: spacing / 6,
        },
      ],
      monthTitle: [
        fontMaker({ weight: "Bold" }),
        { color: mediumEmphasis, fontSize: body1.fontSize },
      ],
      item: [
        styles.item,
        {
          paddingVertical: spacing / 2,
        },
      ],
      selectedItem: [
        styles.selectedItem,
        {
          backgroundColor: accent,
        },
      ],
      dayNumber: [
        fontMaker({ weight: "Bold" }),
        styles.centerText,
        { fontSize: dateNumber.fontSize },
      ],
      selectedText: {
        color: strong,
      },
      dayName: [fontMaker({ weight: "Bold" }), styles.centerText],
      circle: [
        styles.circle,
        {
          marginTop: spacing / 2,
        },
      ],
      tracked: {
        backgroundColor: secondary,
      },
      notTracked: {
        backgroundColor: grey,
      },
    }),
    [
      spacing,
      fontMaker,
      accent,
      strong,
      grey,
      mediumEmphasis,
      body1.fontSize,
      dateNumber.fontSize,
      secondary,
    ],
  )
  const daysOffset = 3
  const generateDateList = (centerDate: Date, daysBefore: number, daysAfter: number): Date[] => {
    const start = new Date(
      Date.UTC(centerDate.getFullYear(), centerDate.getMonth(), centerDate.getDate() - daysBefore),
    )
    const end = new Date(
      Date.UTC(
        centerDate.getFullYear(),
        centerDate.getMonth(),
        centerDate.getDate() + daysAfter + 1,
      ),
    )
    const dateList: Date[] = []

    for (let d = new Date(start); d < end; d.setUTCDate(d.getUTCDate() + 1)) {
      dateList.push(new Date(d))
    }
    return dateList
  }

  const formatDate = (date: Date): string => {
    const dayName = new Intl.DateTimeFormat("fr-FR", { weekday: "short" })
      .format(date)
      .replace(".", "")
      .toLowerCase()
    return `${dayName.charAt(0).toUpperCase() + dayName.slice(1)}`
  }
  const isDateTracked = date => activityTracking.includes(date.toISOString().split("T")[0])
  const [dates, setDates] = useState<Date[]>([])
  const [currentMonth, setCurrentMonth] = useState<string>("")

  useEffect(() => {
    const today = new Date()
    const newDates = generateDateList(today, daysOffset, daysOffset)
    const monthString = today.toLocaleString("default", { month: "long" })
    const formatedMonth = monthString.charAt(0).toUpperCase() + monthString.slice(1)
    setDates(newDates)
    setCurrentMonth(formatedMonth)
  }, [])

  return (
    <View style={s.container}>
      <H6 style={s.monthTitle}>{currentMonth}</H6>
      <View style={s.timelineContainer}>
        {dates.map((date, index) => {
          const isToday = date.toDateString() === new Date().toDateString()
          const tracked = isDateTracked(date)
          return (
            <View key={index} style={s.item}>
              <View key={index} style={[s.item, isToday ? s.selectedItem : {}]}>
                <H6 style={[s.dayNumber, isToday ? s.selectedText : {}]}>{date.getDate()}</H6>
                <Subtitle1 style={[s.dayName, isToday ? s.selectedText : {}]}>
                  {formatDate(date).toLowerCase()}
                </Subtitle1>
              </View>
              <View style={[s.circle, tracked ? s.tracked : s.notTracked]} />
            </View>
          )
        })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  timelineContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  item: {
    width: width / 8,
    justifyContent: "center",
    alignItems: "center",
  },
  selectedItem: {
    borderRadius: 8,
  },
  centerText: {
    textAlign: "center",
  },
  circle: {
    width: 25,
    height: 25,
    borderRadius: 15,
  },
})

export default HorizontalCalendarTimeline
