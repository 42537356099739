import { useIsFocused } from "@react-navigation/native"
import { Body2, Button, useTheme, VectorIconProps, VideoPlayer } from "capsule"
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { VideoRef } from "react-native-video"

import { ItemList } from "../../common/List"
import { ICON_SIZE_MEDIUM } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { useProgram } from "../../features/Providers/ProgramProvider"

interface IProps {
  item: ItemList
  onOpenUrl: () => void
}

const PhaseFaqVideoView: FC<IProps> = ({ item, onOpenUrl }) => {
  const { t } = useTranslation()
  const {
    colors: {
      primary,
      surface: { appUi: surface },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      additionalInfo: {
        backgroundColor: surface,
      },
      label: {
        color: primary,
      },
    }),
    [primary, surface],
  )

  const isFocused = useIsFocused()
  const { prefTabVideo, setPrefTabVideo } = useProgram()
  const [paused, setPaused] = useState(true)
  const videoRef = useRef<VideoRef>(null)
  const onEnd = useCallback(() => {
    setPaused(true)
    setTimeout(() => videoRef?.current?.seek(0), 0)
  }, [])

  useEffect(() => {
    if (!prefTabVideo) {
      setPaused(true)
    }
  }, [prefTabVideo])

  useEffect(() => {
    if (!isFocused) {
      setPaused(true)
    }
  }, [isFocused])

  useEffect(() => {
    if (!isFocused) {
      setPaused(true)
    }
  }, [isFocused])

  const onVideoPress = useCallback(() => {
    if (paused) {
      setPrefTabVideo(true)
    }
    setPaused(p => !p)
  }, [paused, setPrefTabVideo])

  const icon = useMemo<VectorIconProps>(
    () => ({
      name: "play-circle-fill",
      category: "MaterialIcons",
      size: ICON_SIZE_MEDIUM,
      color: primary,
    }),
    [primary],
  )
  const urlTitle = itemField(item, "urlTitle", t)

  return (
    <View style={s.additionalInfo}>
      {item.video ? (
        <VideoPlayer
          isControls={true}
          hasExtraHeader={false}
          uri={item.video}
          forwardRef={videoRef}
          controlledPause={true}
          control={{
            onEnd,
            paused,
          }}
          {...{ onVideoPress, icon }}
        />
      ) : null}
      {item.url ? (
        <Body2>
          <Button mode="text" labelStyle={s.label} onPress={onOpenUrl}>
            {urlTitle}
          </Button>
        </Body2>
      ) : null}
    </View>
  )
}

export default PhaseFaqVideoView
