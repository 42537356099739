import { useFormikContext } from "formik"
import _ from "lodash"
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from "react"
import { FlatList, StyleSheet, View } from "react-native"

import { Question } from "../common/Proms"
import QuestionsType from "../components/QuestionsType"

interface IProps {
  setIsButtonDisabled: Dispatch<SetStateAction<boolean>>
  currentValue: string | number | boolean | string[]
  linkedQuestion: Question[]
  question: Question | undefined
}

const keyExtractor = (item: Question) => item?.id

const FormQuestions: FC<IProps> = ({
  setIsButtonDisabled,
  currentValue,
  linkedQuestion,
  question,
}) => {
  const { setFieldValue } = useFormikContext()
  const formQuestion = useMemo(
    () => (
      <>
        {question ? (
          <QuestionsType item={question} setIsButtonDisabled={setIsButtonDisabled} />
        ) : null}
      </>
    ),
    [question, setIsButtonDisabled],
  )

  useEffect(() => {
    if (linkedQuestion && linkedQuestion.length > 0) {
      _.map(linkedQuestion, item => {
        if (_.isUndefined(item) || (item.showIf && !_.includes(item.showIf, currentValue))) {
          setFieldValue(item.name, item.type === "qcm" ? [] : null)
          if (item.type === "qcm-checkbox" || item.type === "date") {
            setFieldValue(`${item.name}_unknown`, false)
          }
        }
      })
    }
  }, [linkedQuestion, currentValue, setFieldValue])

  const renderItem = useCallback(
    ({ item }) =>
      _.isUndefined(item) || (item.showIf && !_.includes(item.showIf, currentValue)) ? null : (
        <QuestionsType
          isLinkedQuestion={true}
          currentValue={item.type === "date" && item.showIf ? currentValue : undefined}
          {...{ item, setIsButtonDisabled }}
        />
      ),
    [currentValue, setIsButtonDisabled],
  )

  return (
    <>
      {linkedQuestion && linkedQuestion.length > 0 ? (
        <View style={styles.mainView}>
          <FlatList
            data={linkedQuestion}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            ListHeaderComponent={formQuestion}
          />
        </View>
      ) : (
        formQuestion
      )}
    </>
  )
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
  },
})

export default FormQuestions
