import { useNavigation } from "@react-navigation/native"
import { Button, H6, ISO_8601_DATE, Subtitle2, useTheme, VectorIcon } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { FC, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, Text, View } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"

import { ICON_SIZE } from "../features/config/Constants"
import { JourneyNS } from "../features/i18n/constants"
import { getPatientCurrentDateSessions } from "../features/models/PhaseAndSessionFunctions"
import { FirestoreSession } from "../features/models/Session"
import { Patient } from "../features/models/UserData"
import { programContext } from "../features/Providers/ProgramProvider"
import { JourneyScreenNavigationProp } from "../screens/PatientJourneyScreen/PatientJourneyScreen"
import HorizontalCalendarTimeline from "./HorizontalCalendarTimeline"

interface ProtocolViewProps {
  patient: Patient
  isProgramEnded: boolean | undefined
}

const ActivityTrackingComponent: FC<ProtocolViewProps> = ({ patient, isProgramEnded }) => {
  const { t } = useTranslation(JourneyNS)
  const navigation = useNavigation<JourneyScreenNavigationProp>()
  const { currentSession, phaseExercises } = useContext(programContext)
  const [sessionDocuments, setSessionDocuments] = useState<FirestoreSession[]>([])
  const isEmptyExo = _.isEmpty(phaseExercises)
  const stringifyCurrentDate = dayjs().format(ISO_8601_DATE)
  const activityDates = patient.activityTracking ?? []
  const isActivityDay = _.includes(activityDates, stringifyCurrentDate)
  const recurrenceMax = _.maxBy(phaseExercises, ex => ex.recurrence)?.recurrence ?? 0

  useEffect(() => {
    const fetchData = async () => {
      if (patient.id) {
        const sessions = await getPatientCurrentDateSessions(patient.id)
        setSessionDocuments(sessions)
      }
    }
    fetchData()
  }, [patient.id, currentSession])
  const {
    fontMaker,
    colors: {
      white: { highEmphasis: white },
      primary,
      accent,
    },
    dimensions: { spacing },
    typography: { subtitle1 },
  } = useTheme()

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          margin: spacing,
          padding: spacing,
          backgroundColor: white,
        },
      ],
      title: [
        fontMaker({ weight: "Bold" }),
        {
          fontSize: subtitle1.fontSize,
        },
      ],
      buttonText: [
        fontMaker({ weight: "Bold" }),
        {
          color: primary,
          paddingBottom: spacing / 8,
        },
      ],
      buttonView: [
        styles.buttonView,
        {
          marginVertical: spacing,
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      boldText: [fontMaker({ weight: "Bold" })],
      button: {
        marginVertical: spacing,
      },
      text: [
        fontMaker({ weight: "Bold" }),
        styles.text,
        {
          paddingVertical: spacing / 2,
        },
      ],
    }),
    [spacing, fontMaker, primary, white, accent, subtitle1.fontSize],
  )
  const handleOnPress = () => {
    navigation.navigate("ExerciseNavigator")
  }

  return (
    <View style={s.container}>
      <View style={styles.buttonsContainer}>
        <H6 style={s.title}>{t("activityTracking.title")}</H6>
        <TouchableOpacity
          style={styles.calendarButton}
          onPress={() => navigation.navigate("Patient_Calendar", { id: patient.id })}
        >
          <Text style={s.buttonText}>{t("activityTracking.calendarButton")}</Text>
          <VectorIcon
            size={ICON_SIZE}
            name="chevron-right"
            category="MaterialIcons"
            color={primary}
          />
        </TouchableOpacity>
      </View>
      <HorizontalCalendarTimeline activityTracking={patient.activityTracking} />
      <View style={s.buttonView}>
        {isActivityDay && !currentSession && !isEmptyExo ? (
          recurrenceMax === 1 ? (
            <Subtitle2 style={s.text}>{t("common:activityCalendar.oneActivityDay")}</Subtitle2>
          ) : (
            <Subtitle2 style={s.text}>{t("common:activityCalendar.activityDay")}</Subtitle2>
          )
        ) : !isEmptyExo && !isProgramEnded ? (
          isActivityDay && sessionDocuments && sessionDocuments.length >= 1 ? (
            sessionDocuments.length === 1 ? (
              <Subtitle2 style={s.text}>
                {t("common:activityCalendar.oneSessionsDone", {
                  sessionNumber: sessionDocuments.length,
                  totalSessions: recurrenceMax,
                })}
              </Subtitle2>
            ) : (
              <Subtitle2 style={s.text}>
                {t("common:activityCalendar.sessionsDone", {
                  sessionNumber: sessionDocuments.length,
                  totalSessions: recurrenceMax,
                })}
              </Subtitle2>
            )
          ) : (
            <Subtitle2 style={s.text}>{t("common:activityCalendar.inactivityDayToday")}</Subtitle2>
          )
        ) : (
          <Subtitle2 style={s.text}>{t("common:activityCalendar.noExercicesToday")}</Subtitle2>
        )}
        {currentSession && !isEmptyExo && !isProgramEnded ? (
          <Button onPress={() => handleOnPress()} style={s.button} labelStyle={s.boldText}>
            {t("common:activityCalendar.startSession")}
          </Button>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 8,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  calendarButton: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonView: {
    borderRadius: 8,
  },
  text: {
    textAlign: "center",
  },
})

export default ActivityTrackingComponent
