import RNDateTimePicker, { Event as DTEvent } from "@react-native-community/datetimepicker"
import dayjs from "dayjs"
import { useField, useFormikContext } from "formik"
import React, { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Keyboard, Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { Modal, Portal } from "react-native-paper"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import useTheme from "../../features/Theme/ThemeProvider"
import { dateFormatHours } from "../../utils/dateFormat"
import hitSlop from "../../utils/hitSlop"
import { Caption, Subtitle1 } from "../Texts"
import { Touchable } from "../Touchable"
import { VectorIcon, VectorIconProps } from "../VectorIcon"
import { InputContainerStyle } from "./InputContainer"

interface IProps {
  name: string
  label?: string
  format?: string
  showModal?: boolean
  mode: "time" | "date"
  onPress?: () => void
  optionalValue?: string
  underlineColor?: string
  styles?: InputContainerStyle
  touchableStyle?: StyleProp<ViewStyle>
  icons?: { left?: VectorIconProps; right?: VectorIconProps }
  initialValue?: Date
  isChecked?: boolean
  dateType?: "end-date" | "start-date"
  noAdditionalValue?: boolean
}

export const DateTimePicker: FC<IProps> = ({
  label,
  name,
  styles,
  mode,
  format,
  icons,
  onPress,
  showModal = true,
  optionalValue,
  underlineColor,
  touchableStyle,
  initialValue,
  isChecked,
  dateType,
  noAdditionalValue,
}) => {
  const {
    colors: {
      surface,
      primary,
      error,
      black: { highEmphasis: black },
      white: { highEmphasis: white },
    },
    typography: { button },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const { bottom } = useSafeAreaInsets()

  // Formik fields and states
  const [field, meta] = useField<Date>(name)
  const { setFieldValue, setTouched } = useFormikContext()
  // Local fields and states
  const isDate = mode === "date"
  const isAndroid = Platform.OS === "android"
  // due to issue https://github.com/react-native-datetimepicker/datetimepicker/issues/247
  // https://github.com/react-native-datetimepicker/datetimepicker/issues/308
  // textColor cannot be applied on iOS 14
  const display = isAndroid ? "default" : "spinner"
  const isError = meta.touched && meta.error
  const formatValue =
    optionalValue ?? dayjs(field.value).format(format ?? (isDate ? "L" : dateFormatHours()))
  const [show, setShow] = useState(false)

  const s = useMemo(
    () => ({
      input: [
        defaultStyles.input,
        {
          paddingHorizontal: spacing,
          backgroundColor: surface.input,
        },
        touchableStyle,
      ],
      inputContent: [
        defaultStyles.inputContent,
        {
          paddingHorizontal: spacing / 2,
        },
      ],
      button: [
        defaultStyles.button,
        {
          paddingRight: spacing / 2,
          paddingVertical: spacing / 2,
        },
      ],
      label: [
        button,
        {
          color: primary,
        },
      ],
      caption: isError
        ? styles?.error ?? {
            color: error,
          }
        : undefined,
      contentModal: { marginBottom: -bottom }, // Without it, leave transparent background under the picker (iPhone X)
      view: isAndroid ? undefined : { backgroundColor: white },
      underlineColor: { height: 1, backgroundColor: isError ? error : underlineColor ?? primary },
    }),
    [
      spacing,
      surface.input,
      touchableStyle,
      button,
      isError,
      styles?.error,
      error,
      bottom,
      isAndroid,
      white,
      underlineColor,
      primary,
    ],
  )

  const onChange = useCallback(
    (event: DTEvent, selectedDate?: Date) => {
      setShow(Platform.OS === "ios")
      setTouched({ [name]: true })
      setFieldValue(name, selectedDate || field.value)
    },
    [field.value, name, setFieldValue, setTouched],
  )

  const dismissPicker = useCallback(() => {
    setShow(false)
  }, [])

  const onInputPress = useCallback(() => {
    if (initialValue) {
      if (!noAdditionalValue) {
        setFieldValue(`${name}_unknown`, false)
      }
      setFieldValue(name, initialValue)
    }
    Keyboard.dismiss()
    setShow(true)
  }, [setFieldValue, initialValue, name, noAdditionalValue])

  return (
    <>
      <View style={styles?.child}>
        <Touchable rippleColor={primary} style={[s.input]} onPress={onPress ?? onInputPress}>
          <>
            {icons?.left ? <VectorIcon {...icons?.left} /> : null}
            <View style={s.inputContent}>
              {label ? (
                <Caption emphasis="medium" style={s.caption}>
                  {label}
                </Caption>
              ) : null}
              {!isChecked && field.value ? (
                <Subtitle1 emphasis="high">{formatValue}</Subtitle1>
              ) : null}
            </View>
            {icons?.right ? <VectorIcon {...icons?.right} /> : null}
          </>
        </Touchable>
        <View style={s.underlineColor} />
        {isError ? <Caption style={s.caption}>{meta.error}</Caption> : null}
      </View>
      {showModal ? (
        <Portal>
          <Modal
            visible={show}
            style={defaultStyles.modal}
            onDismiss={dismissPicker}
            contentContainerStyle={s.contentModal}
          >
            <View style={s.view}>
              {isAndroid ? null : (
                <Touchable hitSlop={hitSlop(16)} style={s.button} onPress={dismissPicker}>
                  <Caption style={s.label}>{t("common:button.validate")}</Caption>
                </Touchable>
              )}
              {show && (
                <RNDateTimePicker
                  mode={mode}
                  // @ts-ignore exist but only for Android, conflict with iOS props
                  is24Hour
                  display={display}
                  value={field.value}
                  onChange={onChange}
                  textColor={black}
                  locale={dayjs.locale()}
                  maximumDate={dateType === "end-date" ? initialValue : undefined}
                  minimumDate={dateType === "start-date" ? initialValue : undefined}
                />
              )}
            </View>
          </Modal>
        </Portal>
      ) : null}
    </>
  )
}

const defaultStyles = StyleSheet.create({
  input: {
    height: 56,
    alignItems: "center",
    flexDirection: "row",
  },
  inputContent: {
    flex: 1,
    flexDirection: "column",
  },
  button: {
    alignSelf: "flex-end",
  },
  modal: {
    justifyContent: "flex-end",
  },
})
