import { Subtitle1, useTheme, useUser, VectorIcon } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import SettingsList from "../../components/SettingsList"
import { ICON_SIZE, SURGERY_SETTINGS } from "../../features/config/Constants"
import { PatientsListNS, SettingsNS } from "../../features/i18n/constants"

const OncoSurgeriesScreen = () => {
  const {
    colors: { black },
    dimensions: { spacing },
  } = useTheme()
  const { t } = useTranslation()
  const { userData } = useUser()
  const userSurgeries = userData?.medicalInfo?.surgery
  const surgeriesType = userSurgeries?.operation_type
  const mastectomyDate = userSurgeries?.op_mastectomy_date
  const tumorectomyDate = userSurgeries?.op_tumorectomy_date
  const sentinelNodeDate = userSurgeries?.op_sentinel_node_date
  const axCuringDate = userSurgeries?.op_ax_curing_date
  const reconstructiveDate = userSurgeries?.op_reconstructive_date

  const formatedDate = useCallback(data => dayjs(data.toDate()).format("DD/MM/YYYY"), [])

  const s = useMemo(
    () => ({
      rightChildText: {
        marginRight: spacing / 2,
        color: black.mediumEmphasis,
      },
    }),
    [black.mediumEmphasis, spacing],
  )

  const renderRightChild = useCallback(
    text => (
      <View style={styles.rightChildContainer}>
        <Subtitle1 style={s.rightChildText}>{text}</Subtitle1>
        <VectorIcon
          name="chevron-right"
          category="MaterialIcons"
          color={black.mediumEmphasis}
          size={ICON_SIZE}
        />
      </View>
    ),
    [s.rightChildText, black.mediumEmphasis],
  )

  const surgeryItem = useCallback(
    (name, date, formId) => ({
      name: t(`${SettingsNS}:${name}`),
      form: SURGERY_SETTINGS,
      formId,
      rightChild: renderRightChild(
        t("pathology", {
          ns: PatientsListNS,
          name: date ? formatedDate(date) : t(`${PatientsListNS}:date_unknown`),
          interpolation: { escapeValue: false },
        }),
      ),
    }),
    [t, formatedDate, renderRightChild],
  )

  const oncoItems = useMemo(
    () =>
      _.filter([
        _.includes(surgeriesType, "mastectomy")
          ? surgeryItem("mastectomy", mastectomyDate, 1)
          : null,
        _.includes(surgeriesType, "tumorectomy")
          ? surgeryItem("tumorectomy", tumorectomyDate, 2)
          : null,
        _.includes(surgeriesType, "sentinel_node")
          ? surgeryItem("sentinel_node", sentinelNodeDate, 3)
          : null,
        _.includes(surgeriesType, "ax_curing") ? surgeryItem("ax_curing", axCuringDate, 4) : null,
        _.includes(surgeriesType, "reconstructive")
          ? surgeryItem("reconstructive", reconstructiveDate, 5)
          : null,
      ]),

    [
      axCuringDate,
      mastectomyDate,
      reconstructiveDate,
      sentinelNodeDate,
      tumorectomyDate,
      surgeriesType,
      surgeryItem,
    ],
  )

  return (
    <SettingsList listKey="surgery_list" listItems={oncoItems} settingsForm={SURGERY_SETTINGS} />
  )
}

const styles = StyleSheet.create({
  rightChildContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
})

export default OncoSurgeriesScreen
