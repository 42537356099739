import { FirebaseAuthTypes, FirebaseFirestoreTypes, firestore, logger } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import { useContext, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"

import { getDynamicExercise, getFormattedExercises } from "../../algorithm/algorithmFunctions"
import * as callbacks from "../../algorithm/firestoreCallbacks"
import { ExerciseId, ID, SessionId } from "../../common/CommonUserData"
import { Eel, Exercise, Phase } from "../../common/Phase"
import { collections } from "../../common/types"
import {
  convertDecimalHour,
  daySinceSurgery,
  getUtcTime,
  ISO8601_TIME,
} from "../../utils/conversion"
import currentDateMs from "../hooks/useDateMock"
import { itemField } from "../hooks/useItemI18n"
import { programContext } from "../Providers/ProgramProvider"
import { LocalNotification } from "./Alert"
import { Daily, FirestoreSession, SessionDetails } from "./Session"
import { FieldValue } from "./Types"
import { AppUserData, Patient } from "./UserData"

// Create session information list for the current Phase and return phase exercises
export const createSessions = async (
  userData: AppUserData,
  user: FirebaseAuthTypes.User | null,
): Promise<{ details: SessionDetails[]; exercises: Exercise[] } | undefined> => {
  const today = dayjs(currentDateMs())
  if (today.isAfter(userData.programEnd, "day") && userData.specialty !== "onco") {
    throw new Error(`Cannot start after program end date ${userData.programEnd}`)
  }
  const formattedExercises = await getFormattedExercises({
    userData,
    user,
    callbackToGetProgramSessions: callbacks?.getProgramSessions(firestore()),
    callbackToGetPhaseExercises: callbacks?.getPhaseStaticExercises(firestore()),
    callbackToGetEelByIds: callbacks?.getEelByIds(firestore()),
  })
  if (_.isEmpty(formattedExercises)) {
    return undefined
  }

  if (userData.specialty === "onco") {
    const { total, equipments, duration } = getSessionInformations(formattedExercises)
    return {
      details: [
        {
          id: `0`,
          slot: dayjs(userData.alertStartTime).format("HH:mm"), // no slot, one session
          exercises: total,
          duration: Math.floor(duration / 60),
          equipments,
        },
      ],
      exercises: formattedExercises,
    }
  }
  // ortho
  if (!userData.alertStartTime || !userData.alertEndTime) {
    return undefined
  }
  // Get total number of sessions based on the highest recurrence for an exercise
  const recurrenceMax = _.maxBy(formattedExercises, ex => ex.recurrence)?.recurrence ?? 0
  // Get time interval end-start and divide by total number of exercises - 1, exclude the first
  const startTime = userData.alertStartTime.getTime()
  const endTime = userData.alertEndTime.getTime()
  const timeInterval = (endTime - startTime) / (recurrenceMax !== 1 ? recurrenceMax - 1 : 1)
  // Create session description list
  return {
    details: _.times(recurrenceMax, id => {
      const slot = startTime + timeInterval * id
      const { total, equipments, duration } = getSessionInformations(formattedExercises)
      return {
        id: `${id}`,
        slot: getUtcTime(convertDecimalHour(slot)).format(ISO8601_TIME),
        exercises: total,
        duration: Math.floor(duration / 60),
        equipments,
      }
    }),
    exercises: formattedExercises,
  }
}

export const getSessionInformations = (exercises: Exercise[] = []) => ({
  duration: _.sumBy(exercises, ex => ex.duration),
  equipments: _(exercises)
    .flatMap(ex => ex.equipment)
    .uniq()
    .value().length,
  total: exercises.length,
})

/** When HomeScreen is showen, check if a sessions is unfinished
 * {end: undefined && abandoned: undefined && start: !undefined && daySinceSurgery equal }*/
export const isResumedSession = (previousSession?: FirestoreSession, userSurgeryDate?: Date) => {
  if (!previousSession) {
    return false
  }
  return (
    _.isUndefined(previousSession.end) &&
    !_.isUndefined(previousSession.start) &&
    daySinceSurgery(userSurgeryDate) === previousSession.daySinceSurgery &&
    _.isUndefined(previousSession.abandoned)
  )
}

export const updateSession = async (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  sessionId: string | undefined,
  values: Partial<FirestoreSession>,
) => {
  userDocRef
    ?.collection(collections.SESSIONS)
    ?.doc(sessionId)
    ?.set(values, { merge: true })
    ?.catch(e => logger("update session error", e))
}

// Method to determine which session's information to display based on current time
export const getNextSessionSlot = (list: Array<SessionDetails | Record<string, never>>) => {
  const currentTime = dayjs(currentDateMs()).utc(true)
  const last = list.length - 1
  if (currentTime.isBefore(getUtcTime(list[0].slot))) {
    return list[0]
  } else if (currentTime.isAfter(getUtcTime(list[last].slot))) {
    return list[last]
  } else {
    return _.find(list, se =>
      currentTime.isBetween(getUtcTime(se.slot), getUtcTime(list[_.parseInt(se.id) + 1]?.slot)),
    )
  }
}

interface CreateDaily {
  phase: Phase
  userData: AppUserData
  initDailyPhase: (params: Daily) => Promise<void>
  isEdit?: boolean
  createLocalNotifications?: (props: LocalNotification) => Promise<void>
  user: FirebaseAuthTypes.User | null
}
export const createDailyPhase = async ({
  phase,
  userData,
  user,
  initDailyPhase,
  isEdit,
  createLocalNotifications,
}: CreateDaily) => {
  const sessions = await createSessions(userData, user)
  if (!sessions) {
    return false
  }
  await initDailyPhase({
    program: userData.program,
    phase: phase.id,
    exercises: sessions.exercises,
    sessionDetails: sessions.details,
  })
  await createLocalNotifications?.({
    userData,
    isEdit,
    phase,
    sessionDetails: sessions.details,
  })
  return true
}

export const usePhases = (
  surgeryDate: Date | undefined,
  order: "asc" | "desc" = "asc",
  firstActivation?: boolean,
  pathology?: ID,
) => {
  // Appeler useCollectionData sans condition
  const [initPhases] = useCollectionData<Phase>(
    pathology
      ? ((firestore()
          .collection(collections.PHASES)
          .where("pathology", "==", pathology)
          .orderBy("startDay", order) as unknown) as firebase.firestore.Query<Phase>)
      : null,
  )

  const { phases: contextPhases } = useContext(programContext)

  const phases = firstActivation && pathology ? initPhases : contextPhases

  const activePhases = phases?.filter(ph => !ph.isDisabled)
  const currentDate = dayjs(currentDateMs())
  const currentPhase = _.find(activePhases, phase => {
    const startDate = dayjs(surgeryDate).add(phase.startDay, "day")
    const endDate = dayjs(surgeryDate).add(phase.endDay, "day")
    return currentDate.isBetween(startDate, endDate, "day", "[]")
  })

  return useMemo(
    () =>
      order === "desc"
        ? _.maxBy(activePhases, "endDay")
        : !currentPhase
        ? _.head(activePhases)
        : currentPhase,
    [order, currentPhase, activePhases],
  )
}

/** retrieve the next Phase with usePhases complicates
 *  the actualization and detection of the date (not load when appState change or homeScreen is ready).
 *  Too many fields that have a huge impact on the whole phase changing system
 * */
export const getNextPhase = async (pathology: ID | FieldValue, surgeryDate: Date) => {
  const currentDate = dayjs(currentDateMs())
  const phaseDocs = (
    await firestore()
      .collection(collections.PHASES)
      .where("pathology", "==", pathology)
      .orderBy("startDay", "asc")
      .get()
  ).docs
  const phases: Phase[] = _.map(phaseDocs, doc => doc.data() as Phase)
  return _.find(phases, phase => {
    const startDate = dayjs(surgeryDate).add(phase.startDay, "day")
    const endDate = dayjs(surgeryDate).add(phase.endDay, "day")
    return currentDate.isBetween(startDate, endDate, "day", "[]")
  })
}

export const getPatientPreviousSessions = async (patient: Patient, patientId: ID | undefined) => {
  const { program, specialty } = _.pick(patient, ["program", "specialty"])
  let sessionDocuments
  if (specialty === "onco") {
    sessionDocuments = (
      await firestore()
        ?.collection(collections.LOGIN)
        .doc(patientId)
        .collection(collections.SESSIONS)
        .where("program", "==", program)
        .orderBy("start", "desc")
        .get()
    )?.docs
  } else {
    sessionDocuments = (
      await firestore()
        ?.collection(collections.LOGIN)
        .doc(patientId)
        .collection(collections.SESSIONS)
        .orderBy("start", "desc")
        .get()
    )?.docs
  }
  return _.map(sessionDocuments, doc => doc.data() as FirestoreSession)
}
const getTodayTimestamps = () => {
  const startOfDay = dayjs().startOf("day").toDate()
  const endOfDay = dayjs().endOf("day").toDate()

  return {
    start: firestore.Timestamp.fromDate(startOfDay),
    end: firestore.Timestamp.fromDate(endOfDay),
  }
}

export const getPatientCurrentDateSessions = async (patientId: ID | undefined) => {
  const { start, end } = getTodayTimestamps()
  const sessionDocuments = (
    await firestore()
      ?.collection(collections.LOGIN)
      .doc(patientId)
      .collection(collections.SESSIONS)
      .where("end", ">=", start)
      .where("end", "<", end)
      .get()
  )?.docs
  return _.map(sessionDocuments, doc => doc.data() as FirestoreSession)
}

const getSelectedPhaseStaticExercises = async (phase: Phase) => {
  if (!phase?.exercises) {
    const exerciseDocuments = (
      await firestore()
        .collection(collections.EXERCISES)
        .where("intensity", "==", 2)
        .where("disabled", "==", false)
        .where("phase", "==", phase.id)
        .orderBy("order", "asc")
        .get()
    ).docs
    return _.reduce(
      exerciseDocuments,
      (acc, doc) => ({ ...acc, [doc.id]: doc.data() as Exercise }),
      {},
    )
  }
  const exercises = await Promise.all(
    _.map(
      phase.exercises,
      async id =>
        (await firestore().collection(collections.EXERCISES).doc(id).get()).data() as Exercise,
    ),
  )
  return _.keyBy(exercises, "id")
}

const getPhaseEelByIds = async (patient: Patient) => {
  const { pathology } = _.pick(patient, "pathology")
  const pathologyEelDocuments = (
    await firestore().collection(collections.EELS).where("pathology", "==", pathology).get()
  ).docs
  if (pathologyEelDocuments.length === 0) {
    return {}
  }
  const pathologyEelDoc = pathologyEelDocuments?.[0].data() as Eel
  const exercises = await Promise.all(
    _.map(
      pathologyEelDoc.exercises,
      async (exId: ExerciseId) =>
        (await firestore().collection(collections.EXERCISES).doc(exId).get()).data() as Exercise,
    ),
  )
  return _.keyBy(exercises, "id")
}

export const getSelectedExos = async (
  patient: Patient,
  patientId: ID | undefined,
  phase: Phase | undefined,
) => {
  if (!patient || !phase || !phase?.id) {
    return []
  }
  if (!patient.program && patient.specialty === "onco") {
    return []
  }
  const previousSessions = await getPatientPreviousSessions(patient, patientId)
  const exerciseByIds = await getSelectedPhaseStaticExercises?.(phase)
  const exerciseIds = _.map(exerciseByIds, (ex, id) => id)
  const eelByIds = await getPhaseEelByIds?.(patient)
  return _.map(exerciseIds, id => {
    const ex = exerciseByIds?.[id]
    if (_.has(ex, "eels") && ex.eels) {
      const eel = getDynamicExercise(eelByIds, _.clone(ex.eels), previousSessions)
      return {
        ...eel,
        id: eel?.id,
        eels: ex?.eels,
        dynamicId: ex?.id,
      }
    }
    return ex
  }) as Exercise[]
}

export const deleteSessionDocument = (
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null,
  sessionId?: SessionId,
) => {
  userDocRef?.collection(collections.SESSIONS).doc(sessionId).delete()
  userDocRef?.set({ lastSession: firestore.FieldValue.delete() }, { merge: true })
}

export const useProtocolPhases = (isPatient: boolean) => {
  const { t } = useTranslation()
  const { phases } = useContext(programContext)

  const filteredPhases = useMemo(() => _.filter(phases, phase => phase.showProtocoleTab === true), [
    phases,
  ])
  const hasUndefined = isPatient
    ? filteredPhases.some(
        phase => !itemField(phase, "titlePatient", t) || !itemField(phase, "descriptionPatient", t),
      )
    : filteredPhases.some(
        phase =>
          !itemField(phase, "titlePhysician", t) || !itemField(phase, "descriptionPhysician", t),
      )
  if (hasUndefined) {
    return []
  }
  return filteredPhases.map(phase => ({
    titlePhase: isPatient
      ? itemField(phase, "titlePatient", t)
      : itemField(phase, "titlePhysician", t),
    descriptionPhase: isPatient
      ? itemField(phase, "descriptionPatient", t)
      : itemField(phase, "descriptionPhysician", t),
  }))
}
